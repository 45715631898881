.recommend__container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.recommend__content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 85%;
}