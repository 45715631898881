.error__error-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.error__content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 85%;
}

.error__cat-image{
    width: 300px;
    height: auto;
}