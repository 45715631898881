.keddy-icon__cat-image {
    width: 30px;
    height: auto;
    background-color: white;
    border-radius: 30px;
    margin-right: 10px;
}

.keddy-icon__container{
    display: flex;
    flex-direction: row;
    align-items: center;
}