/* src/LoginPage.css */
.loginpage__login-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginpage__form {
  padding-left: 1rem;
  padding-right: 1rem;
}